import { mapActions, mapState, mapGetters } from 'vuex'
import ReportForm from '../ReportForm'

export default {
  name: 'ReportNew',

  components: {
    ReportForm,
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },

  data() {
    return {
      anonymous: this.$attrs.anonymous || false,
    }
  },

  computed: {
    ...mapState(['company']),
    ...mapGetters(['hasMultipleCollaboratorsTypes']),

    showCollaboratorsOptions() {
      return this.hasMultipleCollaboratorsTypes
    },
  },

  methods: {
    ...mapActions(['createReport']),

    async submit(payload) {
      const report = await this.createReport(payload)
      const { match: connect } = payload
      const { slug, slugId } = this.company

      if (!report?.id) return

      if (report?.anonymous) {
        this.$router.push({
          name: 'company-report-subscribe',
          params: {
            slug,
            slugId,
          },
        })
      } else {
        this.$router.push({
          name: 'report-success',
          params: { connect },
        })
      }
    },
  },
}
